<template>
  <div class="bus-station">
    <div class="top-side">
      <div class="top-side__left-side">
        <img src="@/assets/img/busStation.svg" />
        <div class="info-station">
          <h2 class="info-station__title-bus">
            {{ properties.currentStation.code || properties.currentStation.name }}
          </h2>
          <p class="info-station__bus-route">
            {{ properties.currentStation.landmark }}
          </p>
        </div>
      </div>
      <div class="top-side__right-side">
<!--        <button-->
<!--          class="build-btn"-->
<!--        >-->
<!--          Построить маршрут-->
<!--        </button>-->
      </div>
    </div>
    <div class="bottom-side">
      <div class="bus-cards" :class="busInfo.length > 4 ? 'slide' : ''">
        <swiper
          :options="{
            slidesPerView: 2,
            touchRatio: 2,
            slidesPerColumn: 2,
            slidesPerColumnFill: 'column',
            slidesPerGroup: 2,

          }"
        >
          <swiper-slide
            v-for="(item, index) in busInfo"
            :key="index"
            @click.native="goStopBus(item)"
          >
            <bus-card
              :bus-card="item"
            />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import BusCard from "@/components/Parts/BusCard";

export default {
  components: {
    BusCard
  },
  props: {
    properties: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      busInfo: []
    }
  },

  methods: {
    goStopBus(item) {
      this.$emit("show-track", item);
    }
  },

  watch: {
    properties: function(newVal) {

      if (newVal.currentStationData === undefined) {
        this.busInfo = [];
      } else {
        this.busInfo = Object.keys(newVal.currentStationData.routes).map(i => newVal.currentStationData.routes[i]);

        this.busInfo = this.busInfo.sort(function(a, b) {
          return (a.prediction === null) - (b.prediction === null) || +(a.prediction > b.prediction) || -(a.prediction < b.prediction);
        });
      }

    }
  },
}
</script>

<style lang="scss" scoped>
.bus-cards {

  &.slide {
    position: relative;

    &:after {
      content: '';

      display: block;

      position: absolute;

      right: -32px;
      top: 0;

      width: 222px;
      height: 217px;

      background: linear-gradient(270deg, rgba(30, 30, 30, 0.93) 13.1%, rgba(30, 30, 30, 0) 87.73%);

      pointer-events: none;

      z-index: 9;
    }

    &:before {
      content: '';

      display: block;

      width: 50px;
      height: 50px;

      position: absolute;

      right: 4px;
      top: calc(50% - 25px);

      background-image: url("data:image/svg+xml,%3Csvg width='22' height='34' viewBox='0 0 22 34' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.37505 32.7375C5.07434 33.9768 2.96647 33.9768 1.66576 32.7375L1.6642 32.736C0.364474 31.4944 0.361761 29.4799 1.66435 28.2373L1.66576 28.2359L13.4312 16.9996L1.6642 5.76324C0.361678 4.5215 0.361264 2.50468 1.6642 1.26318L1.66576 1.2617C2.96681 0.0251296 5.07193 0.0229974 6.37349 1.26318L21.5459 16.9996' fill='white'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;

      pointer-events: none;

      z-index: 10;
    }
  }
}

.bus-station {
  position: absolute;
  bottom: 0px;
  z-index: 401;
  width: 100%;
  padding: 32px;
  background: #242423;
}

.build-btn {
  width: 238px;
  padding: 20px 15px;
  font-size: 30px;
  line-height: 32px;
  color: #fff;
  border: none;
  border-radius: 100px;
  background: linear-gradient(180deg, #62AFFF 0%, #0B83FF 105.9%);
}
.top-side {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  &__left-side {
    display: flex;

    img {
      flex-shrink: 0;

      margin-right: 24px;
    }

    .info-station {
      width: 98%;
      flex-shrink: 0;
    }
  }

  .info-station {
    &__title-bus {
      margin: 0;
      font-size: 54px;
      font-weight: normal;
      line-height: 55px;
    }

    &__bus-route {
      margin-top: 5px;
      font-size: 30px;
      line-height: 36px;
      color: #fff;
      opacity: 0.7;
    }
  }
}
</style>
